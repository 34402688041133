<template>
  <div class="resetBackground">
    <div class="resetLogo">
      <img src="../assets/uclogo_white.png" alt="" />
    </div>
    <div class="login-card">
      <div class="login-form">
        <div class="form-group">
          <div class="input-lbl">
            <label for="password">Password</label>
          </div>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            size="sm"
          />
        </div>
        <div class="form-group">
          <div class="input-lbl">
            <label for="confirm_password">Confirm Password</label>
          </div>
          <input
            type="password"
            class="form-control"
            id="confirm_password"
            v-model="confirm_password"
            size="sm"
          />
        </div>
        <div class="card-actions">
          <b-button
            size="sm"
            type="submit"
            class="btn btn-primary"
            @click="resetPassword"
          >
            Reset Password
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      confirm_password: "",
    };
  },
  methods: {
    resetPassword() {
      //Check if the token is undefined
      if (!this.$route.query.token) {
        this.makeToast({
          message: "Error: Please click the button in the email we sent to you",
          variant: "danger",
        });
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: this.$route.query.token,
          pass: this.password,
          confirm_pass: this.confirm_password,
        }),
      };
      fetch(
        `${process.env.VUE_APP_API_URL}/users/reset_pass`,
        requestOptions
      )
        .then((res) => {
          res.json().then((json) => {
            if (json.status === "success") {
              this.$router.push("/");
              this.makeToast({
                message: json.message,
                variant: "success",
              });
            } else {
              this.makeToast({
                message: json.message,
                variant: "danger",
              });
            }
          });
        })
        .catch(() => {
          // console.log("err", err);
          this.makeToast({
            message:
              "Error: It seems we may being having a technical difficulties. Please try again later.",
            variant: "danger",
          });
        });
    },
  },
};
</script>

